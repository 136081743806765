import React from "react"
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components"

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Engineer to Product Manager: Learn to make the switch with eng2pm</title>
      </Helmet>
      <GlobalStyle />
      <Body>
        <Container>
          <ValueProp>Learn how to transition from software engineering to product management</ValueProp>
          <p>A tailored, practical, and tested approach to making your next career move:</p>
          <List>
            <ListItem>
              <ListHeader>Project-Based Learning</ListHeader>
              <ListText>We've built the tools to help you validate problems, test solutions, and launch MVPs. By the end of the self-paced program, you'll have multiple product management projects under your belt to showcase to potential employers.</ListText>
            </ListItem>
            <ListItem>
              <ListHeader>Leverage your technical edge</ListHeader>
              <ListText>Software engineers are uniquely positioned to be effective product managers. Learn from others who have made the switch from technical roles to leading great products, so you can put your best foot forward during interviews.</ListText>
            </ListItem>
            <ListItem>
              <ListHeader>Simple and transparent pricing</ListHeader>
              <ListText>We're not a bootcamp or a MOOC. We're software engineers and product managers who know that with the right tools, self-starters can accomplish their career and professional goals on their own terms. We've built the tools needed for aspiring product managers to land their first product role, and we're making them available for <strong>only $20/month</strong>. Satisfaction guaranteed or your money back.</ListText>
            </ListItem>
          </List>
          <CTACopy>Ready to make the switch from engineering to product? Spots are filling up quick:</CTACopy>
          <CTAForm>
          <form
            action="/success"
            data-netlify="true"
            netlify-honeypot="bot-field" 
            method="POST"
            name="CTA"
          >
            <input type="hidden" name="form-name" value="CTA" />
            <label style={{display:'block',marginBottom:6}} ><StyledInput type="text" name="name" placeholder="NAME"/></label>
            <label><StyledInput type="email" name="email" placeholder="EMAIL ADDRESS" /></label>
            <StyledButton type="submit">Join Waitlist</StyledButton>
          </form>
          </CTAForm>
        </Container>
      </Body>
      <ContactLink href="mailto:help@akasihealth.com">Contact</ContactLink>
      <Footer>&#169; 2020 eng2pm</Footer>
    </>
  );
}

export default Home

const GlobalStyle = createGlobalStyle`
  body { 
    background-color: #cbaf87;
    font-family: 'Noto Sans JP', sans-serif;
    margin: 0;
  }
  * {
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
  }
`;

const Body = styled.div`
  align-items: center;
  background: rgb(64,153,171);
  background: #e7dec8;
  color: #30475e;
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 700px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 0.8em;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

const Primary = styled.span`
  color: #30475e;
`;

const Light = styled.span`
  font-weight: 300;
`;

const ValueProp = styled.h2`
  font-size: 2.25em;
  margin: 24px 0;
  margin-top: 0;
  text-align: left;
`;

const List = styled.ul`
  list-style-type: none;
  text-align: left;
`;

const ListItem = styled.li`
  margin-bottom: 24px;
`;

const ListHeader = styled.h3`
  margin: 0;
  text-transform: uppercase;
`;

const ListText = styled.p`
  font-size: 0.9em;
  font-weight: 300;
  margin: 0;
`;

const CTAForm = styled.div`
  display: block;
  margin: 0 auto;
  width: 320px;
`;

const CTACopy = styled.p``;

const StyledInput = styled.input`
  border: none;
  border-radius: 0;
  display: block;
  padding: 12px;
  width: 100%;
`;

const StyledButton = styled.button`
  background-color: #db6400;
  border: none;
  border-radius: 0;
  color: #f8f1f1;
  display: block;
  font-weight: 700;
  margin-top: 9px;
  padding: 12px;
  text-transform: uppercase;
  width: 100%;
`;

const ContactLink = styled.a`
  color: #db6400;
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  margin-top: 24px;
  text-align: center;
  text-transform: uppercase;
`;

const Footer = styled.p`
  color: #30475e;
  display: block;
  font-size: 0.75em;
  margin-top: 24px;
  text-align: center;
`;
